/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/css/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}

@import "@sweetalert2/theme-material-ui/material-ui.scss";
@import "./assets/css/spacing";

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

i.material-icons {
  cursor: pointer;
}

.modal {
  overflow: revert !important;
}

.white-back {
  background: rgba(255, 255, 255, 0.7);
}

.text-bould {
  font-weight: bolder;
}

.btn-all {
  display: block;
  width: 100% !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0,0,0, 0.1);
  padding-bottom: 6%;
}
