@mixin marginBottom($size) {
    margin-bottom: $size;
}

@mixin marginTop($size) {
    margin-top: $size;
}

.no-margin {
    margin: 0 auto !important;
}

.margin-top-2 {
    @include marginTop(2%);
}

.margin-top-5 {
    @include marginTop(5%);
}

@mixin paddingTopBottom($size) {
    padding-top: $size !important;
    padding-bottom: $size !important;
}
.no-padding-sides {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.padding-topBottom-2 {
    @include paddingTopBottom(2%);
}
.padding-topBottom-4 {
    @include paddingTopBottom(4%);
}